import eyeson from 'eyeson';
import { Box, Switch, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ScrollContainer from '../../generic/ScrollContainer.js';

const useStyles = makeStyles({
  table: {
    '& th': {
      fontWeight: 'normal',
      textAlign: 'left',
    },
    '& th, & td': {
      padding: '.25em .5em',
    },
  },
});

const MeetingInfo = (props) => {
  const { participants, hideDuration, toggleHideDuration } = props;
  const classes = useStyles();
  const startDate = new Date(eyeson.room.started_at);
  const day = new Date().getDate();
  const showDate =
    day !== startDate.getDate()
      ? startDate.toLocaleString('default', {
          dateStyle: 'medium',
          timeStyle: 'short',
        })
      : startDate.toLocaleTimeString('default', { timeStyle: 'short' });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        style={{ overflow: 'hidden', height: '100%' }}
      >
        <ScrollContainer>
          <Box margin={2}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th>Room</th>
                  <td>
                    <b>{eyeson.room.name}</b>
                  </td>
                </tr>
                <tr>
                  <th>Started at</th>
                  <td>{showDate}</td>
                </tr>
                <tr>
                  <th>Participants</th>
                  <td>{participants.length}</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </ScrollContainer>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        paddingX={2}
        paddingY={1}
      >
        <label>
          <Typography variant="body2">
            Show duration timer
            <Switch
              checked={!hideDuration}
              onChange={toggleHideDuration}
              // inputProps={{
              //   'aria-label': I18n.t('dialog:autopip:description'),
              // }}
            />
          </Typography>
        </label>
      </Box>
    </>
  );
};

export default MeetingInfo;
