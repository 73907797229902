import eyeson, { Logger, FeatureDetector } from 'eyeson';
import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import createSnackbarConfig from '../utils/SnackbarConfig.js';
import PropTypes from 'prop-types';
import createMuiTheme from '../styles/muiTheme.js';
import {
  getSearchParam,
  guestNamesSuggested,
  updatePermalinkUrl,
} from '../utils/UrlHelpers.js';

import Room from './Room.js';
import Preview from './preview/Preview.js';
import MobilePreview from './preview/MobilePreview.js';
import AppConfig from '../utils/AppConfig.js';
import Connecting from './status/Connecting.js';
import QuickJoinPage from './page/QuickJoinPage.js';
import ErrorStateList from '../utils/ErrorStateList.js';
import KeyboardControl from '../utils/KeyboardControl.js';
import PreferenceStore from '../utils/PreferenceStore.js';
import ErrorPageSelector from './page/ErrorPageSelector.js';
import { matchMedia } from '../utils/LayoutHelper.js';
import { LogAction } from '../utils/actions/ErrorHandlerActions.js';
import VirtualBackgroundTypes from '../utils/VirtualBackgroundTypes.js';
import InterCommunicationHelper from '../utils/InterCommunicationHelper.js';
import YoutubePopup from './YoutubePopup.js';
import StreamdeckHelper from '../utils/StreamdeckHelper.js';

const isDevEnv = (() => {
  const host = window.location.hostname;
  return host === 'localhost' || host === 'app.test-visocon.com';
})();

const checkAppRoute = () => window.location.pathname === '/';
const checkPermalinkRoute = () => window.location.pathname === '/permalink/';

/**
 * Application prepares the initial connection and opens a room
 * component as soon as the meeting starts and all required data is available.
 **/
class Application extends Component {
  constructor(props) {
    super(props);

    this.mqDark = matchMedia('(prefers-color-scheme: dark)');
    const themePref = this.mqDark.matches ? 'dark' : 'light';

    this.config = new AppConfig(process.env, eyeson);
    this.environment = FeatureDetector.environment();
    this.keyboardControl = null;
    this.preferenceStore = new PreferenceStore();
    this.isAppRoute = checkAppRoute();
    this.isPermalinkRoute = checkPermalinkRoute();
    InterCommunicationHelper.init();

    this.state = {
      token: this.props.token,
      theme: this.preferenceStore.getTheme(themePref),
      options: { show_label: true },
      inPreview: true, // ALWAYS show preview before joining(!)
      recording: null,
      snapshots: [],
      broadcasts: [],
      presentation: null,
      mediaOptions: { audio: true, video: true, eco: false },
      connectionStatus: this.initialStatus,
      exitCode: '',
      virtualBackground: 'off',
      virtualBackgroundBlobAvailable: false,
      virtualBackgroundDefault: null,
      widescreen: false,
      streamdeck: 'off',
    };
    this.initStreamdeck();
    if (this.isAppRoute || this.isPermalinkRoute) {
      VirtualBackgroundTypes.translate();
      FeatureDetector.iOSPipCheck().then((enabled) => {
        this.environment.canPip = enabled;
      });
    }
  }

  /**
   * Determines the initial state for connectionStatus.
   **/
  get initialStatus() {
    if (!this.environment.canUseEyeson) {
      return 'unsupported';
    }

    if (this.quickJoinMode) {
      return 'quick-join';
    }

    return 'initialize';
  }

  get quickJoinMode() {
    return getSearchParam('guest') !== null;
  }

  get suggestGuestNames() {
    return guestNamesSuggested();
  }

  get showBranding() {
    if (!this.state.options) {
      return false;
    }
    return Boolean(this.state.options.show_label);
  }

  get custom_options() {
    return this.state.options.custom_fields || {};
  }

  get customLogoUrl() {
    return this.custom_options.logo;
  }

  get feedbackUrl() {
    return this.custom_options.feedback_url;
  }

  get vbgAvailable() {
    const option = this.custom_options.virtual_background;
    const isAvailable = option === true || option === 'true' || isDevEnv;
    const isGuest = eyeson.user.guest === true;
    const optionGuest = this.custom_options.virtual_background_allow_guest;
    const isGuestAvailable =
      optionGuest === true || optionGuest === 'true' || isDevEnv;
    return (
      isAvailable &&
      (!isGuest || isGuestAvailable) &&
      FeatureDetector.canVirtualBackground()
    );
  }

  getCustomOptionBool = (name) => {
    const option = this.custom_options[name];
    return option === true || option === 'true';
  };

  getRetryCounter = (id) => {
    return this.preferenceStore.getRetryCounter(id, this.state.token);
  };

  setRetryCounter = (id, counter) => {
    return this.preferenceStore.setRetryCounter(id, this.state.token, counter);
  };

  /**
   * Register the event handling and start the session.
   **/
  componentDidMount() {
    this.keyboardControl = new KeyboardControl((event) => {
      if (event.type === 'toggle_theme') this.toggleTheme(event.details);
    });
    this.mqDark.addEventListener('change', (event) =>
      this.toggleTheme({ color: event.matches ? 'dark' : 'light' })
    );
    window.document.body.setAttribute('data-theme', this.state.theme);
    if (!this.canConnect()) {
      return;
    }
    if (this.isAppRoute || this.isPermalinkRoute) {
      this.connect(this.state.token);
    }
  }

  componentWillUnmount() {
    this.keyboardControl.destroy();
    InterCommunicationHelper.destroy();
    StreamdeckHelper.destroy();
  }

  toggleTheme = (details = {}) => {
    let newTheme = this.state.theme === 'dark' ? 'light' : 'dark';
    if (details.color) {
      newTheme = details.color;
    }
    this.setState({ theme: newTheme });
    this.preferenceStore.setTheme(newTheme);
    window.document.body.setAttribute('data-theme', newTheme);
  };

  canConnect = () => !this.quickJoinMode && this.environment.canUseEyeson;

  connect = (token) => {
    eyeson.onEvent(this.handleEvent);
    if (checkPermalinkRoute()) {
      eyeson.connectPermalink(token);
    } else {
      eyeson.connect(token);
    }
  };

  /**
   * Handle incoming connection status updates and room_setup messages, ignore
   * all other events.
   **/
  handleEvent = (event) => {
    Logger.debug('Application::handleEvent', event);

    const { type, content, connectionStatus } = event;

    if (type === 'presentation_update') {
      this.setState({ presentation: event.presentation });
    }
    if (type === 'recording_update') {
      this.setState({ recording: event.recording });
    }
    if (type === 'broadcasts_update') {
      this.setState({ broadcasts: event.broadcasts });
    }
    if (type === 'permalink_ready') {
      updatePermalinkUrl(content);
    }

    if (
      !['connection', 'room_setup', 'room_ready'].includes(type) ||
      this.state.connectionStatus === 'meeting_locked'
    ) {
      return;
    }

    if (type === 'room_setup' || type === 'room_ready') {
      let contentSnapshots = content.snapshots;
      if (contentSnapshots) {
        contentSnapshots = content.snapshots.filter((entry) => {
          return entry.created_at && entry.links && entry.links.download;
        });
        contentSnapshots.forEach((entry) => {
          entry.created_at_date = new Date(entry.created_at);
        });
      }
      this.setState(
        {
          options: content.options || this.state.options,
          recording: content.recording || this.state.recording,
          snapshots: contentSnapshots || this.state.snapshots,
          broadcasts: content.broadcasts || this.state.broadcasts,
          presentation: content.presentation || this.state.presentation,
        },
        this.setVirtualBackgroundCustomImage
      );
      this.config.setLocale(content.options);
      InterCommunicationHelper.setOptions(content.options);
      VirtualBackgroundTypes.translate();
      if (content.options.widescreen) {
        window.document.body.setAttribute('data-widescreen', '1');
        this.setState({ widescreen: true });
      }
    }

    if (type === 'room_ready' && content.locked) {
      if (content.user.blocked) {
        this.handleLockMeeting();
        return;
      }
      this.config.locked = true;
    }

    if (type === 'connection' && connectionStatus === 'transport_error') {
      new LogAction({
        name: 'SIP transport error',
        details: 'SIP transport error happened.',
      }).process();
    }

    if (connectionStatus !== 'ready') {
      this.setState({
        connectionStatus: connectionStatus || this.state.connectionStatus,
      });
      return;
    }

    this.setState({ connectionStatus: 'ready' });
  };

  setVirtualBackgroundCustomImage = () => {
    if (!this.vbgAvailable) {
      return;
    }
    if (this.custom_options.virtual_background_image) {
      VirtualBackgroundTypes.addImage(
        this.custom_options.virtual_background_image,
        this.custom_options.virtual_background_image_name || null
      );
    }
    if (this.getCustomOptionBool('virtual_background_allow_custom_only')) {
      VirtualBackgroundTypes.allowOnlyCustomTypes(true);
    }
    if (this.custom_options.virtual_background_allow_local_image || isDevEnv) {
      this.setState({ virtualBackgroundBlobAvailable: true });
    }
    if (this.custom_options.virtual_background_default) {
      this.setState({
        virtualBackgroundDefault:
          this.custom_options.virtual_background_default,
      });
    }
  };

  /**
   * When room props received and room is locked set connection state to show error page and stop all actions
   **/
  handleLockMeeting = () => {
    this.setState({ connectionStatus: 'meeting_locked' });
    eyeson.offEvent(this.handleEvent);
  };

  handlePermalinkUnavailable = () => {
    this.setState({
      inPreview: false,
      connectionStatus: 'permalink_unavailable',
    });
  };

  /**
   * When user decides to finish preview.
   **/
  handleJoin = (mediaOptions) => {
    this.keyboardControl.destroy();
    this.setState({ inPreview: false, mediaOptions });
  };

  /**
   * When a mobile user joins from guest form or switches to call mode via the
   * EcoModeDialog in MobileRoom.
   **/
  handleEnter = (token, opts) => {
    const mediaOptions = Object.assign({ video: true, audio: true }, opts);
    this.setState(
      {
        token,
        mediaOptions,
        inPreview: false,
        connectionStatus: 'connection',
      },
      () => this.connect(token)
    );
  };

  handleOptionsChange = (mediaOptions) => this.setState({ mediaOptions });

  /**
   * Once we've decided the user is inactive, exit and set the connectionStatus
   * to 'inactive'. This will display an ErrorPage informing the user
   * about what just happened.
   **/
  handleInactivity = () => this.handleExit({ reason: 'inactive' });

  /**
   * Handle exit.
   *
   * The exit parameter can contain two properties:
   * - a reason (for the exit)
   * - a redirect condition
   **/
  handleExit = (exit) => {
    Logger.debug('Application::handleExit', exit.reason, exit.code);
    this.setState(
      {
        exitCode: exit.code || '',
        connectionStatus: exit.reason,
      },
      () => {
        eyeson.destroy();

        if (exit.redirect || exit.destination) {
          let { exit_url } = this.state.options;
          if (!exit_url || exit_url.trim() === '') {
            exit_url = exit.destination || this.config.destinations.exit;
          }
          window.location = exit_url;
        }
      }
    );
  };

  updateVirtualBackground = (type) => {
    this.setState({ virtualBackground: type });
  };

  initStreamdeck = () => {
    if (this.environment.isPhone || !StreamdeckHelper.isEnabled()) {
      return;
    }
    StreamdeckHelper.init().then(
      () => {
        this.setState({ streamdeck: 'connected' });
        StreamdeckHelper.onDisconnect(() => {
          this.setState({
            streamdeck: StreamdeckHelper.isEnabled() ? 'not_available' : 'off',
          });
        });
      },
      () => {
        this.setState({ streamdeck: 'not_available' });
      }
    );
  };

  onStreamdeckDisable = () => {
    this.setState({ streamdeck: 'off' });
  };

  /**
   * If a user agent is not supported, or we run into an error we show an
   * appropriate message and information. As soon as we are ready, we stop
   * polling the status and enter the room.
   **/
  renderContent = () => {
    const { theme, connectionStatus, inPreview, recording } = this.state;

    return [
      {
        condition: () => window.location.pathname.startsWith('/youtube-popup'),
        component: () => <YoutubePopup />,
      },
      {
        condition: () =>
          ['initialize', 'fetch_room', 'received_room'].includes(
            connectionStatus
          ),
        component: () => (
          <Connecting
            status={connectionStatus}
            withAnimation={this.showBranding}
          />
        ),
      },
      {
        condition: () => ErrorStateList.includes(connectionStatus),
        component: () => (
          <ErrorPageSelector
            eyeson={eyeson}
            exitCode={this.state.exitCode}
            status={connectionStatus}
            handleExit={this.handleExit}
            environment={this.environment}
            destinations={this.config.destinations}
            options={this.state.options}
            getRetryCounter={this.getRetryCounter}
            setRetryCounter={this.setRetryCounter}
          />
        ),
      },
      {
        condition: () => connectionStatus === 'quick-join',
        component: () => (
          <QuickJoinPage
            eyeson={eyeson}
            config={this.config}
            onEnter={this.handleEnter}
            environment={this.environment}
            suggestGuestNames={this.suggestGuestNames}
            handlePermalinkUnavailable={this.handlePermalinkUnavailable}
          />
        ),
      },
      {
        condition: () => !inPreview && connectionStatus !== 'ready',
        component: () => (
          <Connecting
            status={connectionStatus}
            withAnimation={this.showBranding}
          />
        ),
      },
      {
        condition: () => inPreview && this.environment.isPhone,
        component: () => (
          <MobilePreview
            theme={theme}
            config={this.config}
            onExit={this.handleExit}
            onEnter={this.handleJoin}
            onChange={this.handleOptionsChange}
            onInactivity={this.handleInactivity}
            onThemeToggle={this.toggleTheme}
            isRecording={recording && !recording.duration}
            hasPresenter={this.state.presentation !== null}
            isLiveStreaming={this.state.broadcasts.length > 0}
          />
        ),
      },
      {
        condition: () => inPreview,
        component: () => (
          <Preview
            theme={theme}
            config={this.config}
            onExit={this.handleExit}
            onEnter={this.handleJoin}
            onChange={this.handleOptionsChange}
            streamdeck={this.state.streamdeck}
            isRecording={recording && !recording.duration}
            vbgDefault={this.state.virtualBackgroundDefault}
            vbgAvailable={this.vbgAvailable}
            vbgBlobAvailable={this.state.virtualBackgroundBlobAvailable}
            onInactivity={this.handleInactivity}
            onThemeToggle={this.toggleTheme}
            hasPresenter={this.state.presentation !== null}
            isLiveStreaming={this.state.broadcasts.length > 0}
            virtualBackground={this.state.virtualBackground}
            onStreamdeckDisable={this.onStreamdeckDisable}
            isExperimentalBrowser={this.environment.isExperimentalBrowser}
            updateVirtualBackground={this.updateVirtualBackground}
          />
        ),
      },
      {
        condition: () => true,
        component: () => {
          eyeson.offEvent(this.handleEvent);
          return (
            <Room
              theme={theme}
              token={this.state.token}
              eyeson={eyeson}
              config={this.config}
              onExit={this.handleExit}
              logoUrl={this.customLogoUrl}
              onEnter={this.handleEnter}
              recording={recording}
              snapshots={this.state.snapshots}
              widescreen={this.state.widescreen}
              broadcasts={this.state.broadcasts}
              environment={this.environment}
              mediaOptions={this.state.mediaOptions}
              showBranding={this.showBranding}
              onInactivity={this.handleInactivity}
              onThemeToggle={this.toggleTheme}
              feedbackUrl={this.feedbackUrl}
              hideChat={this.getCustomOptionBool('hide_chat')}
              vbgAvailable={this.vbgAvailable}
              hideRecording={this.getCustomOptionBool('hide_recording')}
              disableRecording={this.getCustomOptionBool('disable_recording')}
              hideGuestLogin={this.getCustomOptionBool('hide_guest_invite')}
              hideScreenshare={this.getCustomOptionBool('hide_screenshare')}
              hideMeetingInfo={this.getCustomOptionBool('hide_meeting_info')}
              virtualBackground={this.state.virtualBackground}
              hideSnapshotButton={this.getCustomOptionBool('hide_snapshot')}
              hideLayoutSettings={this.getCustomOptionBool('hide_layout')}
              screenshareUserPreventLayout={this.getCustomOptionBool(
                'screenshare_user_prevent_layout'
              )}
            />
          );
        },
      },
    ]
      .find((component) => component.condition())
      .component();
  };

  render() {
    const muiTheme = createMuiTheme(this.state.theme);
    return (
      <ThemeProvider theme={muiTheme}>
        <SnackbarProvider {...createSnackbarConfig()}>
          <CssBaseline />
          <div role="application" className="eyeson-embedded-application">
            {this.renderContent()}
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

Application.propTypes = {
  token: PropTypes.string.isRequired,
};

Application.defaultProps = {};

export default Application;
