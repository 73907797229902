import eyeson from 'eyeson';
import { useState, useEffect } from 'react';

const Duration = () => {
  const [startDate] = useState(new Date(eyeson.room.started_at).getTime());
  const [minutes, setMinutes] = useState(0);
  const [duration, setDuration] = useState('0:00');

  const now = Date.now();
  const diff = Math.floor((now - startDate) / 1000 / 60);

  useEffect(() => {
    const hours = Math.floor(minutes / 60);
    const restMinutes = minutes % 60;
    setDuration(`${hours}:${String(restMinutes).padStart(2, '0')}`);
  }, [minutes]);

  if (diff > minutes) {
    setMinutes(diff);
  }

  return <div className="duration">{duration}</div>;
};

export default Duration;
